import { createContext, useContext } from "react";

import { initializeApp } from "firebase/app";
import { getAnalytics, Analytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCYT0pU_gFM3LR2yx2VmCoG2hLUU3TOXn0",
  authDomain: "jude-3-ufba.firebaseapp.com",
  projectId: "jude-3-ufba",
  storageBucket: "jude-3-ufba.appspot.com",
  messagingSenderId: "852760089109",
  appId: "1:852760089109:web:189517748be261a535b41c",
  measurementId: "G-SJN5N99MZ2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AnalyticsContext = createContext<
  { analytics: Analytics; logEvent: (e: string, p?: any) => void } | undefined
>(undefined);

export const AnalyticsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        logEvent: (event: string, properties?: any) =>
          logEvent(analytics, event, properties),
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error("useAnalytics must be used within <AnalyticsProvider>");
  }

  return context;
};
