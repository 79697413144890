import { createContext, useState } from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

import { THEME_CONFIG } from "configs/AppConfig";
import AppLocale, { LocaleKey } from "lang/AppLocale";

type PreferencesContextType = {
  theme: typeof THEME_CONFIG;
  navCollapsed: boolean;
  locale: LocaleKey;
  toggleSidebar: () => void;
  onChangeLocale: (locale: LocaleKey) => void;
};

export const PreferencesContext = createContext<PreferencesContextType | null>(
  null
);

export const PreferencesProvider: React.FC<{ initLocale?: LocaleKey }> = ({
  children,
  initLocale = "pt",
}) => {
  const [locale, setLocale] = useState<LocaleKey>(initLocale);
  const [navCollapsed, setNacollapsed] = useState(false);

  const toggleSidebar = () => setNacollapsed((prev) => !prev);

  const onChangeLocale = (locale: LocaleKey) => {
    setLocale(locale);
  };

  const currentLocale = AppLocale[locale];

  return (
    <PreferencesContext.Provider
      value={{
        theme: THEME_CONFIG,
        navCollapsed,
        locale,
        toggleSidebar,
        onChangeLocale,
      }}
    >
      <IntlProvider
        locale={currentLocale.locale}
        messages={currentLocale.messages}
      >
        <ConfigProvider locale={currentLocale.antd}>{children}</ConfigProvider>
      </IntlProvider>
    </PreferencesContext.Provider>
  );
};
