import { CloseOutlined } from '@ant-design/icons';

import SearchInput from './SearchInput';

type Props = {
  active: boolean;
  close: () => void;
};

export const NavSearch = ({ active, close }: Props) => {
  return (
    <div className={`nav-search ${active ? 'nav-search-active' : ''}`}>
      <div className="d-flex align-items-center w-100">
        <SearchInput close={close} active={active} />
      </div>
      <div className="nav-close" onClick={close}>
        <CloseOutlined />
      </div>
    </div>
  );
};

export default NavSearch;
