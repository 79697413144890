import { QueryClient } from "react-query";
import { Api } from "./generated/JudeApi";

export const customFetch: typeof fetch = async (
  url: RequestInfo | URL,
  options: RequestInit = {}
): Promise<Response> => {
  const jwtToken = localStorage.getItem("token");

  if (jwtToken) {
    options.headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
      ...options.headers,
    };
  }

  return fetch(url.toString(), options);
};

export const api = new Api({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  customFetch,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3600000, // cache de uma hora por padrão
    },
  },
});
