import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'Cannot use Authentication outside of component context scope',
    );
  }

  return context;
};
