import { Link, useLocation } from "react-router-dom";
import { Grid, Menu } from "antd";

import utils from "utils";
import navigationConfig from "configs/NavigationConfig";
import { IntlMessage } from "../util/IntlMessage";
import { Icon } from "../util/Icon";
import { useAuth } from "hooks";
import { CalendarOutlined } from "@ant-design/icons";
import { Links } from "configs/LinkConfig";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

type Props = {
  hideGroupTitle: boolean;
  onMobileNavToggle?: (x: boolean) => void;
};

export const SideNavContent = ({
  hideGroupTitle,
  onMobileNavToggle,
}: Props) => {
  const location = useLocation();
  const { authState, examMode } = useAuth();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle && onMobileNavToggle(false);
    }
  };

  if (examMode) {
    return (
      <Menu
        theme="light"
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        <Menu.ItemGroup title="Prova em andamento" key={"prova"}>
          <Menu.Item key={"contest"}>
            <Icon type={CalendarOutlined} />
            <span>Competições</span>
            <Link
              onClick={() => closeMobileNav()}
              to={Links.app.contests.root}
            />
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  }

  return (
    <Menu
      theme="light"
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      selectedKeys={location.pathname.split("/")}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu, index) =>
        menu.role.filter((role) => authState?.authData?.roles?.includes(role))
          .length > 0 ? (
          menu.submenu.length > 0 ? (
            <SubMenu
              key={`${menu.key}_${index}`}
              title={<IntlMessage id={menu.title} />}
              icon={menu.icon && <Icon type={menu.icon} />}
            >
              {menu.submenu.map((subMenuFirst, index2) =>
                subMenuFirst.role.filter((role) =>
                  authState?.authData?.roles?.includes(role)
                ).length > 0 ? (
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon && <Icon type={subMenuFirst?.icon} />
                      }
                      key={`${subMenuFirst.key}_${index2}`}
                      title={<IntlMessage id={subMenuFirst.title} />}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond, index3) =>
                        subMenuSecond.role.filter((role) =>
                          authState?.authData?.roles?.includes(role)
                        ).length > 0 ? (
                          <Menu.Item key={`${subMenuSecond.key}-${index3}`}>
                            {subMenuSecond.icon && (
                              <Icon type={subMenuSecond?.icon} />
                            )}
                            <span>
                              {<IntlMessage id={subMenuSecond.title} />}
                            </span>
                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            />
                          </Menu.Item>
                        ) : (
                          <></>
                        )
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={`${subMenuFirst.key}-${index2}`}>
                      {subMenuFirst.icon && <Icon type={subMenuFirst.icon} />}
                      <span>{<IntlMessage id={subMenuFirst.title} />}</span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </Menu.Item>
                  )
                ) : (
                  <></>
                )
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={`${menu.key}-${menu.path}`}>
              {menu.icon && <Icon type={menu?.icon} />}
              <span>{<IntlMessage id={menu?.title} />}</span>
              {menu.path && (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              )}
            </Menu.Item>
          )
        ) : null
      )}
    </Menu>
  );
};
