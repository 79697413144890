import { APP_NAME } from "configs/AppConfig";
import { version } from "../../../package.json";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights
        reserved. | {version}
      </span>
    </footer>
  );
}
