import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import { IntlMessage } from "components/util/IntlMessage";

const breadcrumbData: Record<string, any> = {
  app: <IntlMessage id="home" />,
};

const formatBreadcrumb = (pathname: string) => {
  const breadcrumb = breadcrumbData[pathname];
  if (breadcrumb) {
    return breadcrumb;
  }
  return pathname;
};

export const AppBreadcrumb = () => {
  const location = useLocation();

  const pathSnippets = location.pathname.split("/").filter((i: any) => i);
  const buildBreadcrumb = pathSnippets.map((path: any, index: any) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url} style={{ textTransform: "capitalize" }}>
          {formatBreadcrumb(path)}
        </Link>
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb>{buildBreadcrumb}</Breadcrumb>;
};
