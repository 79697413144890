import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import { PreferencesProvider } from "contexts/PreferencesContext";
import { AuthProvider } from "contexts/AuthContext";
import { NotificationProvider } from "contexts/Notification";
import Router from "./views/Router";
import useBodyClass from "hooks/useBodyClass";
import { QueryClientProvider } from "react-query";
import { queryClient } from "api";
import { AnalyticsProvider } from "components/shared/AnalyticsProvider";
import { AmplitudeProvider } from "contexts/Amplitude";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useBodyClass("dir-ltr");

  const theme = window.localStorage.getItem("theme") || "dark";

  return (
    <AnalyticsProvider>
      <AmplitudeProvider>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={theme}
              insertionPoint="styles-insertion-point"
            >
              <PreferencesProvider>
                <AuthProvider>
                  <NotificationProvider>
                    <Router />
                  </NotificationProvider>
                </AuthProvider>
              </PreferencesProvider>
            </ThemeSwitcherProvider>
          </div>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AmplitudeProvider>
    </AnalyticsProvider>
  );
}

export default App;
