import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  alignItems?: 'start' | 'center' | 'end' | 'stretch' | 'baseline';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around';
  mobileFlex?: boolean;
  gap?: number | string;
};

export const Flex = (props: Props) => {
  const {
    children,
    className = '',
    alignItems,
    justifyContent,
    mobileFlex = true,
    flexDirection = 'row',
    gap,
  } = props;
  const getFlexResponsive = () => (mobileFlex ? 'd-flex' : 'd-md-flex');
  return (
    <div
      className={`${getFlexResponsive()} ${className} ${
        flexDirection ? `flex-${flexDirection}` : ''
      } ${alignItems ? `align-items-${alignItems}` : ''} ${
        justifyContent ? `justify-content-${justifyContent}` : ''
      }`}
      style={{ gap }}
    >
      {children}
    </div>
  );
};
