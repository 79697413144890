import { useContext } from "react";
import { AmplitudeContext } from "contexts/Amplitude";

export const useAmplitude = () => {
  const client = useContext(AmplitudeContext);

  if (!client) {
    throw Error("useAmplitude must be used within Amplitude");
  }

  return client;
};
