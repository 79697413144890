import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { AppLayout } from "layouts/AppLayout";
import AuthLayout from "layouts/AuthLayout";
import { Links } from "configs/LinkConfig";
import { AppRoutes } from "views/app/AppRoutes";
import AuthRoutes from "./auth/AuthRoutes";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Loading from "components/shared/Loading";
import { PageViewsListener } from "components/util/PageViewsListener";

const Router = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <BrowserRouter>
      <PageViewsListener>
        <Switch>
          <Route path={Links.auth.root}>
            <AuthLayout>
              <AuthRoutes />
            </AuthLayout>
          </Route>

          <Route path={Links.app.root}>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </Route>

          <Redirect to={Links.app.contests.root} />
        </Switch>
      </PageViewsListener>
    </BrowserRouter>
  );
};

export default Router;
