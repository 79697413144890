import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared/Loading';
import { Links } from 'configs/LinkConfig';

const AuthRoutes = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={Links.auth.login}
          component={lazy(() => import('./authentication/Login'))}
        />
        <Route
          path={Links.auth.resetPassword}
          component={lazy(() => import('./authentication/ResetPassword'))}
        />
        <Redirect from={Links.auth.root} to={Links.auth.login} />
      </Switch>
    </Suspense>
  );
};

export default AuthRoutes;
