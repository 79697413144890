import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "constants/ThemeConstant";

export const APP_NAME = "JUDE UFBA";
export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

export const THEME_CONFIG = {
  sideNavTheme: SIDE_NAV_LIGHT,
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  currentTheme: "dark",
};
