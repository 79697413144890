import { ComponentType } from 'react';

type Props = {
  type: ComponentType;
  [key: string]: any;
};

export const Icon = ({ type: Component, ...rest }: Props) => {
  return <Component {...rest} />;
};

export default Icon;
