export const AmplitudeEvents = {
  global: {
    pageView: 'Navigation - Page View',
    changeMainSection: 'Navigation - Change main section',
    login: {
      success: 'Global - Login - Success',
      failure: 'Global - Login - Failure',
    },
    logout: 'Global - Logout success',
  },
  allocation: {
    navigation: {
      changeAllocationSection: 'Navigation - Allocation - Change section',
    },
    transfer: {
      goToOrder: 'Allocation - Transfer - Go to orders',
      submit: 'Allocation - Transfer - Submit',
      goToDetails: 'Allocation - Transfer - View Details',
      uploadFile: 'Allocation - Transfer - Upload File',
      delete: 'Allocation - Transfer - Delete',
      openTransferLineDetails:
        'Allocation - Transfer - Open transfer line item details',
    },
    order: {
      changeOrderStatType: 'Allocation - Order - Change order stat type filter',
      changeTransferBatchFilter:
        'Allocation - Order - Change transfer batch filter',
    },
  },
  brain: {
    compareFilters: {
      submit: 'Brain - Compare Model - Submit',
    },
  },
} as const;
