import {
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
// import { APP_NAME } from "configs/AppConfig";
import utils from "utils";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

type Props = {
  navCollapsed?: boolean;
  mobileLogo?: boolean;
  logoType?: string;
};

const getLogoWidthGutter = (props: Props, isMobile: boolean) => {
  const { navCollapsed } = props;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

// const getLogo = (props: Props) => {
//   const { navCollapsed, logoType } = props;
//   if (logoType === "light") {
//     if (navCollapsed) {
//       return "/img/logo-sm.png";
//     }
//     return "/img/logo.png";
//   }

//   if (navCollapsed) {
//     return "/img/logo-sm.jpeg";
//   }
//   return "/img/logo.png";
// };

const getLogoDisplay = (isMobile: boolean, mobileLogo?: boolean) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  }
  return "logo";
};

// const getImageHeight = ({ navCollapsed }: Props) =>
//   navCollapsed ? "60px" : "40px";

export const Logo = (props: Props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const { navCollapsed } = props;
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{
        width: `${getLogoWidthGutter(props, isMobile)}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <img
        src={getLogo(props)}
        alt={`${APP_NAME} logo`}
        height={getImageHeight(props)}
      /> */}
      <b>{navCollapsed ? "JUDE" : "JUDE UFBA"}</b>
    </div>
  );
};
