import { createContext } from "react";
import appInfo from "../../package.json";
import amplitude from "amplitude-js";

import { AMPLITUDE_KEY } from "configs/AppConfig";

export const initAmplitude = () => {
  if (!AMPLITUDE_KEY) {
    throw Error("AMPLITUDE_KEY is not defined");
  }
  const client = amplitude.getInstance();

  client.init(AMPLITUDE_KEY);
  amplitude.getInstance().setVersionName(appInfo.version);
  return client;
};

export const AmplitudeContext = createContext<{
  amplitudeClient: amplitude.AmplitudeClient;
  logEvent: (e: string, data?: any) => void;
} | null>(null);

export const AmplitudeProvider: React.FC<{
  client?: amplitude.AmplitudeClient;
}> = ({ children, client = initAmplitude() }) => {
  return (
    <AmplitudeContext.Provider
      value={{
        logEvent: (e: string, data: any) => client.logEvent(e, data),
        amplitudeClient: client,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
};
