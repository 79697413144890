import { Layout } from 'antd';
import { SIDE_NAV_DARK, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';

import { usePreferences } from 'hooks/usePreferences';
import { SideNavContent } from './SideNavContent';

const { Sider } = Layout;

export const SideNav = () => {
  const {
    navCollapsed,
    theme: { sideNavTheme },
  } = usePreferences();
  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''
      }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <SideNavContent hideGroupTitle={false} />
      </Scrollbars>
    </Sider>
  );
};
