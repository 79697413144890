import { useAnalytics } from "components/shared/AnalyticsProvider";
import { AmplitudeEvents } from "constants/AmplitudeEvents";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PageViewsListener: React.FC = ({ children }) => {
  const location = useLocation();
  const { logEvent } = useAnalytics();

  useEffect(() => {
    logEvent(AmplitudeEvents.global.pageView, {
      page: location.pathname,
    });
  }, [location.pathname, logEvent]);

  return <>{children}</>;
};
