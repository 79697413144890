import { ComponentType } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { UserRole } from "contexts/AuthContext";
import { useAuth } from "hooks/useAuth";
import { Links } from "configs/LinkConfig";

export interface Props extends RouteProps {
  component: ComponentType;
  requiredRole: UserRole[];
}
export const PrivateRoute = ({
  component: Component,
  requiredRole,
  ...rest
}: Props) => {
  const { authState } = useAuth();

  const autorized =
    requiredRole.filter((role) => authState?.authData?.roles?.includes(role))
      .length > 0;

  return (
    <Route
      {...rest}
      render={() => {
        if (autorized) {
          return <Component />;
        }

        return <Redirect to={Links.app.home} />;
      }}
    />
  );
};
