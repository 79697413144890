import { useEffect, useState } from "react";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { usePreferences } from "hooks/usePreferences";
import { Logo } from "./Logo";
import { NavSearch } from "./NavSearch";
import { NavProfile } from "./NavProfile";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { NavThemeSelector } from "./NavThemeSelector";
import { useAmplitude } from "hooks";

const { Header } = Layout;

export const HeaderNav = (props: any) => {
  const { navCollapsed, toggleSidebar } = usePreferences();
  const { currentTheme } = useThemeSwitcher();
  const { logEvent } = useAmplitude();

  const { headerNavColor, isMobile } = props;
  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    logEvent("toggle_sidebar");
    toggleSidebar();
  };

  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };

  const navMode = mode();

  const getNavWidth = () => {
    if (isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper`}>
        <Logo logoType={navMode} navCollapsed={navCollapsed} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              <li
                className="ant-menu-item ant-menu-item-only-child"
                onClick={() => {
                  onToggle();
                }}
              >
                {navCollapsed || isMobile ? (
                  <MenuUnfoldOutlined className="nav-icon" />
                ) : (
                  <MenuFoldOutlined className="nav-icon" />
                )}
              </li>
            </ul>
          </div>
          <div className="nav-right">
            <NavThemeSelector />
            {/* <NavLanguage /> */}
            <NavProfile />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
