import enLang from "./entries/en_US";
import frLang from "./entries/fr_FR";
import PtBrLang from "./entries/pt_BR";

export type LocaleKey = "en" | "fr" | "pt";
export type Locale = typeof enLang | typeof frLang | typeof PtBrLang;

const AppLocale: Record<LocaleKey, Locale> = {
  en: enLang,
  fr: frLang,
  pt: PtBrLang,
};

export default AppLocale;
