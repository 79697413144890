import { FormattedMessage } from 'react-intl';

export const IntlMessage = (props: any) => <FormattedMessage {...props} />;

export const T = ({
  id,
  values,
}: {
  id: string;
  values?: Record<string, any>;
}) => <IntlMessage id={id} values={values} />;

export default IntlMessage;
