import { ComponentType } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { useAuth } from "hooks/useAuth";
import { LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { Icon } from "components/util/Icon";
import { IntlMessage } from "components/util/IntlMessage";
import { useHistory } from "react-router-dom";
import { useAmplitude } from "hooks";

type NavMeuItem = {
  title: string;
  icon: ComponentType;
  path: string;
};

const menuItem: NavMeuItem[] = [];

export const NavProfile = () => {
  const { authState, signOut } = useAuth();
  const history = useHistory();
  const { logEvent } = useAmplitude();

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div
            className="pl-3 w-100"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <h4 className="mb-0">{authState.authData?.firstName}</h4>
            <span className="text-muted">
              {authState.authData?.username}
            </span>{" "}
            <br />
            <span className="text-muted">{authState.authData?.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key="change_password"
            onClick={(e) => {
              logEvent("on_click_open_change_password_screen");
              history.push("/app/change-password");
            }}
          >
            <span>
              <LockOutlined className="mr-3" />
              <span className="font-weight-normal">
                <IntlMessage id="navprofile.changepassword" />
              </span>
            </span>
          </Menu.Item>
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              logEvent("on_click_logout");
              signOut();
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                <IntlMessage id="navprofile.signout" />
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar style={{ backgroundColor: "black" }}>
            {authState.authData?.firstName?.split("")[0]?.toUpperCase()}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
