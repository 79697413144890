import React, { useCallback, useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import Loading from "components/shared/Loading";
import { useQueryClient } from "react-query";
import { useAnalytics } from "components/shared/AnalyticsProvider";
import { setUserId, setUserProperties } from "firebase/analytics";

import { useAmplitude } from "hooks/useAmplitude";
import { api } from "api";

export const STORAGE_KEY_TOKEN = "token";

export type UserRole =
  | "UNAUTHENTICATED"
  | "contestant"
  | "administrator"
  | "professor";

export interface AuthData {
  username: string;
  userId: string;
  roles: UserRole;
  version: string;
  expiresOn: number;
  email: string;
  dateJoined: Date;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isAdmin: boolean;
}

export interface AuthState {
  isSignedIn: boolean;
  bearerToken?: string;
  authData: AuthData;
}

export type AuthContextType = {
  authState: AuthState;
  setToken: (token: string) => void;
  removeToken: () => void;
  signOut: () => void;
  examMode?: boolean;
};

export const AuthContext = React.createContext<AuthContextType | null>(null);

const initialState: AuthState = {
  isSignedIn: false,
  bearerToken: "",
  authData: {
    username: "",
    expiresOn: -1,
    roles: "UNAUTHENTICATED",
    userId: "",
    version: "",
    email: "",
    dateJoined: new Date(),
    firstName: "",
    lastName: "",
    isActive: false,
    isAdmin: false,
  },
};

const loadTokenFromStorage = () => {
  const token = localStorage.getItem(STORAGE_KEY_TOKEN);
  if (!token) {
    return undefined;
  }

  const parsedToken: AuthData = jwt.decode(token, {
    json: true,
  }) as AuthData;

  if (parsedToken?.expiresOn && parsedToken?.expiresOn <= Date.now()) {
    return undefined;
  }
  return token;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [authState, setAuthState] = useState(initialState);
  const queryClient = useQueryClient();
  const { analytics } = useAnalytics();
  const { amplitudeClient } = useAmplitude();

  const [examMode, setExamMode] = useState(false);

  const setToken = useCallback(
    (bearerToken: string) => {
      if (bearerToken) {
        localStorage.setItem(STORAGE_KEY_TOKEN, bearerToken);
        const payload: any = jwt.decode(bearerToken);

        const authData: AuthData = {
          userId: payload.user_id,
          username: payload.user.username,
          roles: payload.user.roles,
          email: payload.user.email,
          firstName: payload.user.first_name,
          lastName: payload.user.last_name,
          isActive: payload.user.is_active,
          dateJoined: new Date(payload.user.date_joined),
          version: payload.version,
          expiresOn: payload.exp,
          isAdmin:
            payload.user.roles?.includes("administrator") ||
            payload.user.roles?.includes("professor"),
        };

        setUserId(analytics, payload.user.email);
        setUserProperties(analytics, {
          ...payload.user,
        });

        amplitudeClient.setUserId(authData.email);
        amplitudeClient.setUserProperties(authData);

        amplitudeClient.logEvent("login", {
          ...payload.user,
        });

        setAuthState(
          (prev) =>
            ({
              ...prev,
              authData,
              bearerToken,
              isSignedIn: true,
            } as any)
        );
      }
    },
    [analytics, amplitudeClient]
  );

  const removeToken = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY_TOKEN);
    setAuthState(initialState);
    queryClient.clear();
    amplitudeClient.clearUserProperties();
  }, [queryClient, amplitudeClient]);

  const signOut = () => removeToken();

  // Load token from LocalStorage on load
  useEffect(() => {
    const token = loadTokenFromStorage();
    if (token) {
      setToken(token);
    } else {
      removeToken();
    }
    setReady(true);
  }, [removeToken, setToken]);

  useEffect(() => {
    if (!authState.isSignedIn) return;
    async function checkExam() {
      // TODO: remove this and wrap in a context to avoid duplicate calls to current contest API
      const { data: currentContests } = await api.contest.contestCurrent();
      setExamMode(
        currentContests.results.some(
          (p) => p.contest_type === 1 && p.hide_others
        ) && authState.authData.roles === "contestant"
      );
    }

    checkExam();
  }, [authState]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setToken,
        removeToken,
        signOut,
        examMode,
      }}
    >
      {ready ? children : <Loading />}
    </AuthContext.Provider>
  );
};
