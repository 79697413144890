import { ComponentType } from "react";

import {
  CalendarOutlined,
  FileTextOutlined,
  TagsOutlined,
  SolutionOutlined,
  UserOutlined,
  DashboardOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Links } from "./LinkConfig";
import { UserRole } from "contexts/AuthContext";

type MenuItem = {
  key: string;
  path: string;
  title: string;
  icon: ComponentType<{ className?: string }>;
  breadcrumb: boolean;
  submenu: MenuItem[];
  role: UserRole[];
};

const navigationConfig: MenuItem[] = [
  // {
  //   key: "home",
  //   path: Links.app.home,
  //   title: "home",
  //   icon: HomeOutlined,
  //   breadcrumb: false,
  //   role: ["contestant", "administrator", "professor"],
  //   submenu: [],
  // },
  {
    key: "users",
    path: Links.app.users.root,
    title: "users",
    icon: UserOutlined,
    breadcrumb: true,
    role: ["administrator", "professor"],
    submenu: [
      // {
      //   key: "users.list",
      //   path: Links.app.users.root,
      //   title: "users",
      //   icon: UserOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
      // {
      //   key: "users.add",
      //   path: Links.app.users.create,
      //   title: "users.create.title",
      //   icon: PlusOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
      // {
      //   key: "users.batch",
      //   path: Links.app.users.batch,
      //   title: "users.batch.title",
      //   icon: PlusOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
    ],
  },
  {
    key: "contests",
    path: Links.app.contests.root,
    title: "contests",
    icon: CalendarOutlined,
    breadcrumb: true,
    role: ["contestant", "administrator", "professor"],
    submenu: [
      // {
      //   key: "contests.list",
      //   path: Links.app.contests.root,
      //   title: "contests",
      //   icon: OrderedListOutlined,
      //   breadcrumb: false,
      //   role: ["contestant", "administrator", "professor"],
      //   submenu: [],
      // },
      // {
      //   key: "contests.create",
      //   path: Links.app.contests.create,
      //   title: "contests.create.title",
      //   icon: PlusOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
    ],
  },
  {
    key: "problems",
    path: Links.app.problems.root,
    title: "problems",
    icon: FileTextOutlined,
    breadcrumb: true,
    role: ["administrator", "professor"],
    submenu: [
      // {
      //   key: "problems.list",
      //   path: Links.app.problems.root,
      //   title: "problems",
      //   icon: OrderedListOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
      // {
      //   key: "problems.add",
      //   path: Links.app.problems.create,
      //   title: "problems.create.title",
      //   icon: PlusOutlined,
      //   breadcrumb: false,
      //   role: ["administrator", "professor"],
      //   submenu: [],
      // },
    ],
  },
  {
    key: "tags",
    path: Links.app.tags.root,
    title: "tags",
    icon: TagsOutlined,
    breadcrumb: true,
    role: ["administrator", "professor"],
    submenu: [],
  },
  {
    key: "submissions",
    path: Links.app.submissions.root,
    title: "submissions.title",
    icon: SolutionOutlined,
    breadcrumb: true,
    role: ["administrator", "professor", "contestant"],
    submenu: [],
  },
  {
    key: "grade_report",
    path: Links.app.gradeReport.root,
    title: "gradeReport.title",
    icon: DashboardOutlined,
    breadcrumb: true,
    role: ["administrator", "professor", "contestant"],
    submenu: [],
  },
  {
    key: "material",
    path: Links.app.material.root,
    title: "materials.title",
    icon: BookOutlined,
    breadcrumb: true,
    role: ["administrator", "professor", "contestant"],
    submenu: [],
  },
];

export default navigationConfig;
