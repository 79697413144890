import { Menu, Switch, Tooltip } from "antd";
import { useAmplitude } from "hooks";
import { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export const NavThemeSelector = () => {
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState(currentTheme === "dark");
  const { logEvent } = useAmplitude();

  const toggleTheme = () => {
    logEvent("on_click_toggle_theme", { isDarkMode });
    setIsDarkMode((previous) => {
      const newTheme = previous ? themes.light : themes.dark;
      switcher({ theme: newTheme });
      window.localStorage.setItem("theme", newTheme);
      return !previous;
    });
  };

  return (
    <Menu mode="horizontal">
      <Menu.Item key="language">
        <Tooltip title="Toggle Dark Mode">
          <Switch checked={isDarkMode} onChange={() => toggleTheme()} />
        </Tooltip>
      </Menu.Item>
    </Menu>
  );
};
