import { Drawer } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { Flex } from 'components/shared/Flex';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { usePreferences } from 'hooks/usePreferences';
import { SideNavContent } from './SideNavContent';
import { Logo } from './Logo';

export const MobileNav = ({
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
}: any) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };
  const { navCollapsed, toggleSidebar } = usePreferences();

  const onClose = () => toggleSidebar();

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={navCollapsed}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <SideNavContent {...props} onMobileNavToggle={onClose} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav;
