import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useT = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (id: string, values: Record<string, any> = {}) =>
      formatMessage({ id }, values),
    [formatMessage],
  );
};
