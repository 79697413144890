import { createContext } from 'react';
import { notification } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification';

export const NotificationContext = createContext<NotificationInstance | null>(
  null,
);

export const NotificationProvider: React.FC = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={api}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
