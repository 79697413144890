import { useCallback, useState } from 'react';

import { API_BASE_URL } from 'configs/AppConfig';
import { useAuth } from 'hooks/useAuth';

const uploadPath = `${API_BASE_URL}/upload`;

export const useUploadFile = () => {
  const [uploading, setUploading] = useState(false);
  const {
    authState: { bearerToken },
  } = useAuth();

  const uploadFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('report', file);
      const requestOptions = {
        method: 'POST',
        referrer: uploadPath,
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        body: formData,
      };
      setUploading(true);
      const response = await fetch(uploadPath, requestOptions);
      const json = await response.json();
      setUploading(false);
      return json;
    },
    [bearerToken],
  );

  return { uploadFile, uploading };
};
