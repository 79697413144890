import { useLocation } from "react-router-dom";
import { Grid, Layout } from "antd";

import { SideNav } from "components/layout/SideNav";
import { MobileNav } from "components/layout/MobileNav";
import { HeaderNav } from "components/layout/HeaderNav";
import { PageHeader } from "components/layout/PageHeader";
import Footer from "components/layout/Footer";
import navigationConfig from "configs/NavigationConfig";
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { usePreferences } from "hooks/usePreferences";
import { NPSQuestion, SurveyPopUp } from "components/shared/NpsQuestion";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const {
    navCollapsed,
    theme: { navType },
  } = usePreferences();
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const getLayoutDirectionGutter = () => {
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        {isNavSide && !isMobile && <SideNav />}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            {/* <NPSQuestion /> */}
            {/* <SurveyPopUp /> */}
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>{children}</Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};
